<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <validation-observer slim v-slot="{ handleSubmit }">
        <v-row>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'facebook')"
              :label="$t('hr.employee.social_media.facebook')"
              :rules="{
                max: 255,
                regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
              }"
              :index="0"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.facebook"
                  outlined
                  dense
                  color="blue darken-4"
                  prepend-inner-icon="mdi-facebook"
                  style="max-width: 400px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'linkedin')"
              :label="$t('hr.employee.social_media.linkedin')"
              :rules="{
                max: 255,
                regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
              }"
              :index="1"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.linkedin"
                  outlined
                  dense
                  color="blue"
                  prepend-inner-icon="mdi-linkedin"
                  style="max-width: 400px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'twitter')"
              :label="$t('hr.employee.social_media.twitter')"
              :rules="{
                max: 255,
                regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
              }"
              :index="2"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.twitter"
                  outlined
                  dense
                  color="blue lighten-1"
                  prepend-inner-icon="mdi-twitter"
                  style="max-width: 400px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'instagram')"
              :label="$t('hr.employee.social_media.instagram')"
              :rules="{
                max: 255,
                regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
              }"
              :index="3"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.instagram"
                  outlined
                  dense
                  color="#bc2a8d"
                  prepend-inner-icon="mdi-instagram"
                  style="max-width: 400px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'skype')"
              :label="$t('hr.employee.social_media.skype')"
              :rules="{
                max: 255,
                regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
              }"
              :index="4"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.skype"
                  outlined
                  dense
                  color="blue darken-1"
                  prepend-inner-icon="mdi-skype"
                  style="max-width: 400px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <template v-for="(objectAttributeValue, idx) in employeePayload.objectAttributeValues">
            <v-col :key="idx" cols="12" class="py-0">
              <profile-field
                :value="objectAttributeValue.value"
                :label="objectAttributeValue.name"
                :index="5 + idx"
                :active-index="activeIndex"
                :saving="saving"
                :loading="loading"
                :is-editable="isEditable"
                @click:edit="onEditClick"
                @click:save="handleSubmit(onSaveClick)"
                @click:cancel="onCancelClick"
              >
                <template v-slot="{ errors }">
                  <v-text-field
                    v-model="objectAttributeValue.value"
                    outlined
                    dense
                    color="tertiary"
                    style="max-width: 400px;"
                    :error-messages="errors"
                  />
                </template>
              </profile-field>
            </v-col>
          </template>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EMPLOYEE_SOCIAL_MEDIA } from "../query";

  export default {
    name: "EmployeeSocialMedia",
    props: {
      profileSettings: Object
    },
    components: {
      ProfileField: () => import("./components/ProfileField")
    },
    data: () => ({
      loading: false,
      employeePayload: {
        linkedin: null,
        facebook: null,
        instagram: null,
        twitter: null,
        skype: null,
        objectAttributeValues: []
      },
      employee: null,
      activeIndex: -1,
      saving: false
    }),
    computed: {
      isEditable() {
        return this.$helpers.get(this.profileSettings, this.$enums.PROFILE_SETTINGS.SOCIAL_MEDIA);
      }
    },
    methods: {
      onSaveClick() {
        this.saving = true;
        this.$api.employeeService
          .updateSocialMediaCredentials(this.$store.state.auth.user.id, this.employeePayload)
          .then(response => {
            if (response.status === 200) {
              this.activeIndex = -1;
              this.fetchEmployeeSocialMedia();
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.saving = false));
      },
      onCancelClick() {
        this.activeIndex = -1;
        this.setEmployeePayload(this.employee);
      },
      onEditClick(index) {
        this.activeIndex = index;
      },
      setEmployeePayload(employee) {
        this.employeePayload = { ...employee };
        this.employeePayload.objectAttributeValues = this.$helpers.cloneDeep(employee.objectAttributeValues);
      },
      async fetchEmployeeSocialMedia() {
        this.loading = true;
        this.$apollo
          .query({
            query: EMPLOYEE_SOCIAL_MEDIA,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee.socialMedia;
              this.setEmployeePayload(data.employee.socialMedia);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchEmployeeSocialMedia();
    }
  };
</script>
